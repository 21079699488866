<template>
  <div :class="$style.home">
    <div :class="$style.left">
      <div :class="$style.phone">
        <div :class="$style.head">
          <img :src="oss + '/manage/media/assets/statusbar_w.png'" />
        </div>
        <div :class="$style.content">
          <div v-if="!showImg" :style="{ height: !data.fullscreen ? '616px' : '716px' }" :class="$style.block" />
          <img v-else :style="{ height: !data.fullscreen ? '616px' : '716px' }" :src="showImgArr[imgIndex]" alt="" />
        </div>
        <div :class="$style.time">3秒跳过</div>
        <div :class="$style.reset" @click="reset()">
          <i class="ri-refresh-line" />
        </div>
        <div v-if="!data.fullscreen" :class="$style.logo">
          <img :src="user.cultural_cloud_logo" alt="" /> {{ user.cultural_cloud_name }}
        </div>
        <div :class="$style.foot">
          <img :src="oss + '/manage/media/assets/homebar.png'" />
        </div>
      </div>
    </div>
    <div :class="$style.right">
      <p :class="$style.title">
        配置列表
        <a-popover placement="bottomLeft" title="说明">
          <template slot="content">
            <p>配置列表中，开启一个配置只显示一条数据；如果开启多个配置，将随机显示其中一条数据。</p>
          </template>
          <i class="ri-question-line" />
        </a-popover>
      </p>
      <a-table :columns="columns" :data-source="data.data" :pagination="false" row-key="created_at">
        <template #open="item">
          <BjSwitch v-model="item.show" size="default" @change="radioChange" />
        </template>
        <template #cover="item">
          <img :class="$style.cover" :src="item.image.cover" alt="" />
        </template>
        <template #link="item">
          {{ item.link.label }}
        </template>
        <!-- prettier-ignore -->
        <template #action="item, e, cIndex">
            <i v-permission="'terminal.mobile.screen-edit'" class="ri-edit-line" :class="$style.edit" @click="onEdit(item, cIndex)" />
            <a-popconfirm title="确定要删除吗？" @confirm="onDelete(item, cIndex)">
              <i v-permission="'terminal.mobile.screen-edit'" :class="$style.delete" class="ri-delete-bin-7-line" />
            </a-popconfirm>
          </template>
      </a-table>
      <BjButton
        v-permission="'terminal.mobile.screen-create'"
        :class="$style.addBtn"
        :disabled="data.data.length > 9"
        :tooltips="data.data.length > 9 ? '开屏图片最多设置10条，超过10条将不能再次添加。' : ''"
        type="dashed"
        @click="onAdd()"
      >
        <i class="ri-add-line left" />
        添加开屏
      </BjButton>
      <div :class="$style.line" />
      <div :class="$style.box">
        <div :class="$style.boxLeft">全屏显示</div>
        <div :class="$style.boxRight">
          <a-radio-group v-model="data.fullscreen" v-permission="'terminal.mobile.screen-edit'" @change="radioChange">
            <a-radio :value="0"> 非全屏 </a-radio>
            <a-radio :value="1"> 全屏 </a-radio>
          </a-radio-group>
        </div>
      </div>
      <div :class="$style.box">
        <div :class="$style.boxLeft">显示设置</div>
        <div :class="$style.boxRight">
          <a-radio-group v-model="data.show_type" v-permission="'terminal.mobile.screen-edit'" @change="radioChange">
            <a-radio :value="0"> 当天首次访问显示 </a-radio>
            <a-radio :value="1"> 每次访问显示 </a-radio>
          </a-radio-group>
        </div>
      </div>
    </div>

    <bj-modal
      :title="title"
      :visible="visible.add"
      :class="$style.modal"
      :width="520"
      :body-style="{ height: '500px', 'overflow-y': 'auto' }"
      @ok="ok"
      @cancel="cancel"
    >
      <BjForm>
        <BjInput
          v-model="add.title"
          label="开屏标题"
          v-bind="layout"
          tooltips
          label-align="left"
          rules="required"
          placeholder="请输入开屏标题"
        />
        <a-row class="mt-10">
          <a-col :class="$style.title" span="6">
            <span class="required">*</span>
            开屏图片
          </a-col>
          <a-col span="18">
            <selectMaterial :allow="['image']" :data="add.image" @change="coverChange" />
            <p class="tip mt-10">开屏图片建议尺寸640px*1380px；大小不超过2M；格式支持JPG、PNG。</p>
          </a-col>
        </a-row>
        <a-row class="mt-10" :class="$style.title">
          <a-col span="6">
            <span class="required">*</span>
            跳转链接
          </a-col>
          <a-col span="18">
            <BjButton class="btn-default" @click="onAddLink()">
              <i class="ri-link left" />
              设置链接
            </BjButton>
            <p v-if="add.link" class="mt-10">{{ add.link.label }}</p>
            <p class="tip mt-10">请选择点击开屏图片需要跳转的链接。</p>
          </a-col>
        </a-row>
        <a-row class="mt-10" :class="$style.title">
          <a-col span="6">
            <span class="required">*</span>
            开启状态
          </a-col>
          <a-col span="18">
            <BjSwitch v-model="add.show" size="default" />
          </a-col>
        </a-row>
      </BjForm>
    </bj-modal>
    <chooseLink :visible.sync="visible.link" :data="add.link" @change="getLink" />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'

import chooseLink from '@/components/chooseLink'
import selectMaterial from '@/components/selectMaterial'
import { terminalScreenService } from '@/service'

const service = new terminalScreenService()

export default {
  name: 'Home',
  components: {
    selectMaterial,
    chooseLink,
  },
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      data: {
        data: [],
        fullscreen: 0,
        show_type: 0,
      },
      add: {
        title: null,
        image: {},
        link: {},
        show: 1,
      },
      visible: {
        add: false,
        link: false,
      },
      title: '添加开屏',
      index: null,
      showImgArr: [],
      imgIndex: 0,
    }
  },
  computed: {
    ...mapState(['user']),
    showImg() {
      return !!this.showImgArr.length
    },
    columns() {
      return [
        {
          title: '',
          width: 100,
          scopedSlots: {
            customRender: 'open',
          },
        },
        {
          title: '开屏标题',
          dataIndex: 'title',
          ellipsis: true,
        },
        {
          title: '开屏图片',
          scopedSlots: {
            customRender: 'cover',
          },
        },
        {
          title: '跳转链接',
          scopedSlots: {
            customRender: 'link',
          },
        },
        {
          title: '添加时间',
          dataIndex: 'created_at',
        },
        {
          title: '管理',
          width: 150,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ]
    },
  },
  created() {
    this.getInfo()
  },
  methods: {
    reset() {
      if (this.showImgArr.length === 1) {
        this.imgIndex = 0
      } else {
        if (this.showImgArr.length - 1 === this.imgIndex) {
          this.imgIndex = 0
        } else {
          this.imgIndex++
        }
      }
    },
    onEdit(item, index) {
      this.index = index
      this.visible.add = true
      this.add = item
    },
    onDelete(item, index) {
      this.data.data.splice(index, 1)
      this.save()
    },
    onAdd() {
      this.visible.add = true
      this.index = null
      this.add = {
        title: null,
        image: {},
        link: null,
        show: 1,
      }
    },
    radioChange() {
      this.save()
    },
    onAddLink() {
      this.visible.link = true
    },
    getLink(data) {
      this.add.link = _.cloneDeep(data)
    },
    coverChange(data) {
      this.add.image = data[0]
    },
    async getInfo() {
      const { data } = await service.getInfo({
        page_type: 'splash',
      })
      this.data = data
      this.showImgArr = []
      data.data.forEach(item => {
        if (item.show) {
          this.showImgArr.push(item.image.cover)
        }
      })
    },
    async ok() {
      try {
        let tmp = _.cloneDeep(this.data.data)
        if (this.index !== null) {
          tmp.splice(this.index, 1, this.add)
        } else {
          tmp.push(this.add)
        }
        await service.save({
          page_type: 'splash',
          data: JSON.stringify({ ...this.data, data: tmp }),
        })
        this.$message.success(this.index !== null ? '修改成功' : '添加成功')
        this.cancel()
        this.getInfo()
      } catch (e) {}
    },
    async save() {
      try {
        await service.save({
          page_type: 'splash',
          data: JSON.stringify(this.data),
        })
        this.getInfo()
      } catch (e) {}
    },
    cancel() {
      this.visible.add = false
    },
  },
}
</script>

<style lang="less" module>
.home {
  display: flex;

  .add-btn {
    margin-top: 20px;
  }

  .left {
    position: relative;
    width: 390px;
    height: 750px;
    margin-right: 20px;

    .phone {
      position: relative;
      width: 390px;
      height: 100%;
      // height: calc(100vh - 152px);
      // max-height: 750px;
      overflow-x: hidden;
      overflow-y: auto;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);

      .head {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        z-index: 9;
        width: 100%;
        height: 44px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .content {
        position: absolute;
        z-index: 1;

        img {
          width: 390px;
          height: 616px;
          object-fit: cover;
        }

        .block {
          width: 390px;
          height: 616px;
          background-color: #5c5c5c;
        }
      }

      .time {
        position: absolute;
        top: 74px;
        right: 30px;
        z-index: 3;
        width: 71px;
        height: 27px;
        color: #fff;
        font-size: 14px;
        line-height: 27px;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 100px;
      }

      .reset {
        position: absolute;
        right: 20px;
        bottom: 20px;
        z-index: 3;
        width: 44px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        background-color: @primary-color;
        border-radius: 100%;
        cursor: pointer;

        i {
          color: #fff;
          font-size: 22px;
        }
      }

      .logo {
        position: absolute;
        bottom: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100px;
        color: #000;
        font-size: 20px;
        background-color: #fff;

        img {
          width: 36px;
          height: 36px;
          margin: 0 12px 0 0;
          border-radius: 100%;
        }
      }

      .foot {
        position: absolute;
        bottom: 0;
        z-index: 2;
        background: #fff;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .right {
    flex: 1;
    padding: 20px;
    background: #fff;

    .title {
      color: #000;
      font-size: 16px;

      i {
        color: #5c5c5c;
        font-size: 20px;
        vertical-align: -4px;
      }
    }

    .line {
      width: 100%;
      margin: 20px 0;
      border-top: 1px solid #eee;
    }

    .box {
      display: flex;

      &-left {
        width: 140px;
        font-size: 14px;
        line-height: 40px;
      }

      &-right {
        flex: 1;
        line-height: 40px;
      }
    }

    .cover {
      width: 60px;
      height: 60px;
      object-fit: cover;
    }

    .edit {
      margin-right: 10px;
      color: @primary-color;
      font-size: 18px;
    }

    .delete {
      color: @error-color;
      font-size: 18px;
    }
  }
}

.modal {
  .title {
    line-height: 40px;
  }
}
</style>
